var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-form",
    _vm._b(
      {
        staticClass: "mt-4",
        attrs: { layout: "vertical", form: _vm.form },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.submitForm.apply(null, arguments)
          }
        }
      },
      "a-form",
      _vm.formItemLayout,
      false
    ),
    [
      _c(
        "a-form-item",
        { attrs: { label: _vm.$t(_vm.formRules.assetNumber.label) } },
        [
          _c("a-input", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: _vm.formRules.assetNumber.decorator,
                expression: "formRules.assetNumber.decorator"
              }
            ],
            attrs: {
              disabled: "",
              name: _vm.formRules.assetNumber.name,
              placeholder: _vm.$t(_vm.formRules.assetNumber.placeholder)
            }
          })
        ],
        1
      ),
      _c(
        "a-form-item",
        { attrs: { label: _vm.$t(_vm.formRules.unit.label) } },
        [
          _c("a-input", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: _vm.formRules.unit.decorator,
                expression: "formRules.unit.decorator"
              }
            ],
            attrs: {
              disabled: "",
              name: _vm.formRules.unit.name,
              placeholder: _vm.$t(_vm.formRules.unit.placeholder)
            }
          })
        ],
        1
      ),
      _c(
        "a-form-item",
        { attrs: { label: _vm.$t(_vm.formRules.transferDate.label) } },
        [
          _c("a-date-picker", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: _vm.formRules.transferDate.decorator,
                expression: "formRules.transferDate.decorator"
              }
            ],
            staticClass: "w-100",
            attrs: {
              disabled: "",
              name: _vm.formRules.transferDate.name,
              placeholder: _vm.$t(_vm.formRules.transferDate.placeholder),
              format: _vm.DEFAULT_DATE_FORMAT
            }
          })
        ],
        1
      ),
      _c(
        "a-form-item",
        { attrs: { label: _vm.$t(_vm.formRules.location.label) } },
        [
          _c(
            "a-select",
            {
              directives: [
                {
                  name: "decorator",
                  rawName: "v-decorator",
                  value: _vm.formRules.location.decorator,
                  expression: "formRules.location.decorator"
                }
              ],
              attrs: {
                disabled: !!_vm.idReferenceNumber,
                name: _vm.formRules.location.name,
                placeholder: _vm.$t(_vm.formRules.location.placeholder),
                autocomplete: "false",
                allowClear: true,
                filterOption: false,
                "show-search": "",
                loading: _vm.loadingLocation
              },
              on: {
                search: function(value) {
                  return _vm.getListOfWarehouseLocation(value)
                }
              }
            },
            _vm._l(_vm.dataListWarehouseLocation.data, function(item, index) {
              return _c(
                "a-select-option",
                { key: index, attrs: { value: item.id } },
                [
                  _c(
                    "a-tooltip",
                    [
                      _c("template", { slot: "title" }, [
                        _vm._v(
                          " " +
                            _vm._s(item.warehouse.branchWarehouse.name) +
                            " , " +
                            _vm._s(item.warehouse.name) +
                            " , " +
                            _vm._s(item.name) +
                            " "
                        )
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(item.warehouse.branchWarehouse.name) +
                          " , " +
                          _vm._s(item.warehouse.name) +
                          " , " +
                          _vm._s(item.name) +
                          " "
                      )
                    ],
                    2
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      ),
      _c(
        "a-form-item",
        { attrs: { label: _vm.$t(_vm.formRules.customerLocation.label) } },
        [
          _c("a-input", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: _vm.formRules.customerLocation.decorator,
                expression: "formRules.customerLocation.decorator"
              }
            ],
            attrs: {
              disabled: "",
              name: _vm.formRules.customerLocation.name,
              placeholder: _vm.$t(_vm.formRules.customerLocation.placeholder)
            }
          })
        ],
        1
      ),
      _c(
        "a-form-item",
        { attrs: { label: _vm.$t(_vm.formRules.employee.label) } },
        [
          _c(
            "a-select",
            {
              directives: [
                {
                  name: "decorator",
                  rawName: "v-decorator",
                  value: _vm.formRules.employee.decorator,
                  expression: "formRules.employee.decorator"
                }
              ],
              attrs: {
                disabled: !!_vm.idReferenceNumber,
                name: _vm.formRules.employee.name,
                placeholder: _vm.$t(_vm.formRules.employee.placeholder),
                autocomplete: "false",
                allowClear: true,
                filterOption: _vm.filterOption,
                "show-search": "",
                loading: _vm.loadingEmployee
              },
              on: {
                search: function(value) {
                  return _vm.getListContact(value)
                }
              }
            },
            _vm._l(_vm.dataSourceEmployee, function(item, index) {
              return _c(
                "a-select-option",
                { key: index, attrs: { value: item.id } },
                [
                  _c(
                    "a-tooltip",
                    [
                      _c("template", { slot: "title" }, [
                        _vm._v(" " + _vm._s(item.fullName) + " ")
                      ]),
                      _vm._v(" " + _vm._s(item.fullName) + " ")
                    ],
                    2
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      ),
      _c(
        "a-form-item",
        { attrs: { label: _vm.$t(_vm.formRules.nikemployee.label) } },
        [
          _c("a-input", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: _vm.formRules.nikemployee.decorator,
                expression: "formRules.nikemployee.decorator"
              }
            ],
            attrs: {
              disabled: "",
              name: _vm.formRules.nikemployee.name,
              placeholder: _vm.$t(_vm.formRules.nikemployee.placeholder)
            }
          })
        ],
        1
      ),
      _c(
        "a-form-item",
        { attrs: { label: _vm.$t(_vm.formRules.serialnumber.label) } },
        [
          _c("a-input", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: _vm.formRules.serialnumber.decorator,
                expression: "formRules.serialnumber.decorator"
              }
            ],
            attrs: {
              disabled: !!_vm.idReferenceNumber,
              name: _vm.formRules.serialnumber.name,
              placeholder: _vm.$t(_vm.formRules.serialnumber.placeholder)
            }
          })
        ],
        1
      ),
      _c(
        "a-form-item",
        { attrs: { label: _vm.$t(_vm.formRules.unitcode.label) } },
        [
          _c("a-input", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: _vm.formRules.unitcode.decorator,
                expression: "formRules.unitcode.decorator"
              }
            ],
            attrs: {
              disabled: !!_vm.idReferenceNumber,
              name: _vm.formRules.unitcode.name,
              placeholder: _vm.$t(_vm.formRules.unitcode.placeholder)
            }
          })
        ],
        1
      ),
      _c(
        "a-form-item",
        { attrs: { label: _vm.$t(_vm.formRules.isActive.label) } },
        [
          _c("a-switch", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: _vm.formRules.isActive.decorator,
                expression: "formRules.isActive.decorator"
              }
            ],
            attrs: { disabled: !!_vm.idReferenceNumber }
          })
        ],
        1
      ),
      _c(
        "a-button",
        {
          staticStyle: { "margin-right": "0.5rem" },
          attrs: { type: "danger" },
          on: {
            click: function($event) {
              return _vm.cancelHandle()
            }
          }
        },
        [_vm._v(_vm._s(_vm.$t("lbl_cancel")))]
      ),
      _vm.$can("update", "asset-adjustment")
        ? _c(
            "a-button",
            {
              key: "submit",
              attrs: {
                type: "primary",
                loading: _vm.isFormSubmitted,
                "html-type": "submit",
                disabled: _vm.disabledSave
              },
              on: {
                click: function($event) {
                  return _vm.submitForm($event)
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("lbl_save")) + " ")]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }